import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import language_config from "../../../language-config";

import "./style.scss";

const SeeMore = ({text, colorful, to, onClick, state}) => {
    let [path, setPath] = useState('/');

    useEffect(() => {
        let newPath = ''
        if (typeof window !== 'undefined') {
            newPath = ((language_config[window.localStorage.language].is_default ? '' : ('/' + window.localStorage.language)) + '/')
        } else {
            newPath = '/'
        }
        setPath(newPath)
    }, []);

    return (
        <>
            {to &&
                <Link className={`see-more ie ${colorful ? "home" : ""}`} to={`${path}${to}`} state={state}>
                    <svg width="28.898" height="14.656" viewBox="0 0 28.898 14.656">
                        <path d="M21.382,33.786a1.308,1.308,0,0,0,.4-.957,1.26,1.26,0,0,0-.4-.942L15.4,25.9a1.26,1.26,0,0,0-.942-.4,1.308,1.308,0,0,0-.957.4,1.283,1.283,0,0,0-.389.942,1.333,1.333,0,0,0,.389.957l3.694,3.679H-5.766a1.308,1.308,0,0,0-.957.4,1.283,1.283,0,0,0-.389.942,1.333,1.333,0,0,0,.389.957,1.333,1.333,0,0,0,.957.389H17.195L13.5,37.868a1.283,1.283,0,0,0-.389.942,1.328,1.328,0,0,0,1.346,1.346,1.283,1.283,0,0,0,.942-.389Z" transform="translate(7.112 -25.5)"/>
                    </svg>
                    {text}
                </Link>
            }
            {onClick &&
                <button className={`see-more ie ${colorful ? "home" : ""}`} onClick={onClick} onKeyDown={onClick}>
                    <svg width="28.898" height="14.656" viewBox="0 0 28.898 14.656">
                        <path d="M21.382,33.786a1.308,1.308,0,0,0,.4-.957,1.26,1.26,0,0,0-.4-.942L15.4,25.9a1.26,1.26,0,0,0-.942-.4,1.308,1.308,0,0,0-.957.4,1.283,1.283,0,0,0-.389.942,1.333,1.333,0,0,0,.389.957l3.694,3.679H-5.766a1.308,1.308,0,0,0-.957.4,1.283,1.283,0,0,0-.389.942,1.333,1.333,0,0,0,.389.957,1.333,1.333,0,0,0,.957.389H17.195L13.5,37.868a1.283,1.283,0,0,0-.389.942,1.328,1.328,0,0,0,1.346,1.346,1.283,1.283,0,0,0,.942-.389Z" transform="translate(7.112 -25.5)"/>
                    </svg>
                    {text}
                </button>
            }
        </>
    )
}

export default SeeMore
