import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import SeeMore from "../components/SeeMore"
import StoryBanner from "../components/StoryBanner"
import Slider from "../components/Slider"
import CardCareer from "../components/Cards/CardCareer"
import Title from "../components/Title"
import HeaderSimple from "../components/HeaderSimple"
import StoriaNumber from "../components/StoriaNumber"

import banner from "../images/section-story.jpg"
import ChiSiamoInfo1 from "../images/chi-siamo-info-1.jpg"
import ChiSiamoInfo2 from "../images/chi-siamo-info-2.jpg"
import story1958 from "../images/history/story-1958.jpg"
import story60 from "../images/history/story-60.jpg"
import story70 from "../images/history/story-70.jpg"
import story80 from "../images/history/story-80.jpg"
import story90 from "../images/history/story-90.jpg"
import story00 from "../images/history/story-00.jpg"
import story2013 from "../images/history/story-2013.jpg"
import story2019 from "../images/history/story-2019.jpg"
import storydec2019 from "../images/history/story-dec2019.jpg"
import podcast from "../images/history/podcast-thumb.png"
import spotify from "../images/history/spotify.svg"
import youtube from "../images/history/youtube.svg"

import "../utils/page-chiSiamo.scss"
import "../utils/general.scss"

const ChiSiamo = ({ pageContext }) => {
    return (
        <Layout pageContext={pageContext}>
            <SEO
                title={pageContext.localeResources["menu.chiSiamo"]}
                description="Pam Panorama è una società di Gruppo Pam, storica realtà italiana del settore della Grande Distribuzione che ha da poco festeggiato i suoi primi 60 anni di attività."
            >
                {typeof window !== "undefined" && (
                    <link href={window.location} rel="canonical" />
                )}
            </SEO>

            <div className="container">
                <Slider
                    content={[
                        {
                            text: pageContext.localeResources["chiSiamo.slide"],
                            image: banner,
                        },
                    ]}
                    style={{ maxHeight: "60vh" }}
                />
            </div>

            <HeaderSimple
                subtitle={
                    pageContext.localeResources["chiSiamo.first.subtitle"]
                }
                title={pageContext.localeResources["chiSiamo.first.title"]}
            />
            <div className="container">
                <section className="section section-text">
                    <p
                        className="text ie"
                        data-aos="fade-right"
                        data-aos-offset="200"
                        dangerouslySetInnerHTML={{
                            __html:
                                pageContext.localeResources[
                                    "chiSiamo.first.text1"
                                ],
                        }}
                    ></p>
                    <p
                        className="text ie"
                        data-aos="fade-left"
                        data-aos-offset="200"
                        dangerouslySetInnerHTML={{
                            __html:
                                pageContext.localeResources[
                                    "chiSiamo.first.text2"
                                ],
                        }}
                    ></p>
                </section>
            </div>

            <section className="section-motto colorful-bg">
                <div className="container">
                    <h4 data-aos="fade-right" data-aos-offset="100">
                        {pageContext.localeResources["chiSiamo.motto"]}
                    </h4>
                </div>
            </section>

            <div className="container">
                <section className="section section-quote">
                    <h5
                        className="subtitle gray ie"
                        data-aos="fade-right"
                        data-aos-offset="0"
                    >
                        {pageContext.localeResources["chiSiamo.quote.subtitle"]}
                    </h5>
                    <h5
                        className="quote ie"
                        data-aos="fade-left"
                        data-aos-offset="0"
                    >
                        {pageContext.localeResources["chiSiamo.quote.title"]}
                    </h5>
                </section>
            </div>

            <section
                className="section-info colorful-bg right"
                style={{ backgroundImage: `url(${ChiSiamoInfo1})` }}
            >
                <div className="container">
                    <p
                        data-aos="fade-right"
                        data-aos-offset="0"
                        dangerouslySetInnerHTML={{
                            __html:
                                pageContext.localeResources[
                                    "chiSiamo.promises.intro"
                                ],
                        }}
                    ></p>
                </div>
            </section>

            <div className="container">
                <section className="section section-pros">
                    <div data-aos="fade-up" data-aos-offset="0" className="ie">
                        <h5 className="subtitle gray">01</h5>
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.promises.first"
                                    ],
                            }}
                        ></p>
                    </div>
                    <div
                        data-aos="fade-up"
                        data-aos-offset="100"
                        className="ie"
                    >
                        <h5 className="subtitle gray">02</h5>
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.promises.second"
                                    ],
                            }}
                        ></p>
                    </div>
                    <div
                        data-aos="fade-up"
                        data-aos-offset="200"
                        className="ie"
                    >
                        <h5 className="subtitle gray">03</h5>
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.promises.third"
                                    ],
                            }}
                        ></p>
                    </div>
                    <div
                        data-aos="fade-up"
                        data-aos-offset="300"
                        className="ie"
                    >
                        <h5 className="subtitle gray">04</h5>
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.promises.fourth"
                                    ],
                            }}
                        ></p>
                    </div>
                    <div
                        data-aos="fade-up"
                        data-aos-offset="400"
                        className="ie"
                    >
                        <h5 className="subtitle gray">05</h5>
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.promises.fifth"
                                    ],
                            }}
                        ></p>
                    </div>
                </section>
            </div>

            <section
                className="section-info colorful-bg left"
                style={{ backgroundImage: `url(${ChiSiamoInfo2})` }}
            >
                <div className="container">
                    <p
                        data-aos="fade-left"
                        dangerouslySetInnerHTML={{
                            __html:
                                pageContext.localeResources[
                                    "chiSiamo.acronimo"
                                ],
                        }}
                    ></p>
                </div>
            </section>

            <StoryBanner
                size="small"
                showButton={false}
                pageContext={pageContext}
            />

            <section className="section-podcast">
                <h2
                    data-aos="fade-up"
                    dangerouslySetInnerHTML={{
                        __html:
                            pageContext.localeResources[
                                "chiSiamo.podcast.title"
                            ],
                    }}
                ></h2>
                <div className="list__podcast">
                    <div className="item__podcast" data-aos="fade-right">
                        <div className="box__img ">
                            <img className="img-fluid" src={podcast} alt="" />
                        </div>
                        <div className="box__text ">
                            <h4
                                data-aos="fade-left"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.1.title"
                                        ],
                                }}
                            ></h4>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.1.text"
                                        ],
                                }}
                            ></p>
                        </div>
                    </div>

                    <div className="item__podcast" data-aos="fade-left">
                        <div className="box__img ">
                            <img className="img-fluid" src={podcast} alt="" />
                        </div>
                        <div className="box__text ">
                            <h4
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.2.title"
                                        ],
                                }}
                            ></h4>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.2.text"
                                        ],
                                }}
                            ></p>
                        </div>
                    </div>

                    <div className="item__podcast" data-aos="fade-right">
                        <div className="box__img ">
                            <img className="img-fluid" src={podcast} alt="" />
                        </div>
                        <div className="box__text ">
                            <h4
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.3.title"
                                        ],
                                }}
                            ></h4>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.3.text"
                                        ],
                                }}
                            ></p>
                        </div>
                    </div>

                    <div className="item__podcast" data-aos="fade-left">
                        <div className="box__img ">
                            <img className="img-fluid" src={podcast} alt="" />
                        </div>
                        <div className="box__text ">
                            <h4
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.4.title"
                                        ],
                                }}
                            ></h4>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.4.text"
                                        ],
                                }}
                            ></p>
                        </div>
                    </div>
                    <div className="item__podcast" data-aos="fade-left">
                        <div className="box__img ">
                            <img className="img-fluid" src={podcast} alt="" />
                        </div>
                        <div className="box__text ">
                            <h4
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.5.title"
                                        ],
                                }}
                            ></h4>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.5.text"
                                        ],
                                }}
                            ></p>
                        </div>
                    </div>
                    <div
                        className="item__podcast"
                        style={{ background: "unset" }}
                        data-aos="fade-left"
                    ></div>
                </div>
                <div className="listen__podcast">
                    <h3
                        dangerouslySetInnerHTML={{
                            __html:
                                pageContext.localeResources[
                                    "chiSiamo.podcast.listen.title"
                                ],
                        }}
                    ></h3>
                    <div className="info__icons" data-aos="fade-down">
                        <a href="https://youtu.be/jDWqqWb-8e4" target="_blank">
                            <img className="img-fluid" src={youtube} alt="" />
                            <span
                                className="icon__youtube"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.listen.youtube"
                                        ],
                                }}
                            ></span>
                        </a>
                        <a
                            href="https://open.spotify.com/show/7wa9VhvVvAkwUkBuuuHE6m?si=jVYl5TNxQJ2AcTn2Q-zcQA"
                            target="_blank"
                        >
                            <img className="img-fluid" src={spotify} alt="" />
                            <span
                                className="icon__spotify"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        pageContext.localeResources[
                                            "chiSiamo.podcast.listen.spotify"
                                        ],
                                }}
                            ></span>
                        </a>
                    </div>
                </div>
            </section>
            <section>
                <HeaderSimple
                    title={
                        pageContext.localeResources[
                            "chiSiamo.story.header.title"
                        ]
                    }
                    subtitle={
                        pageContext.localeResources[
                            "chiSiamo.story.header.subtitle"
                        ]
                    }
                />
                <div className="container">
                    <p
                        className="story-main-p"
                        dangerouslySetInnerHTML={{
                            __html:
                                pageContext.localeResources[
                                    "chiSiamo.story.mainDescription"
                                ],
                        }}
                    ></p>
                    <div className="story-section">
                        <StoriaNumber
                            text={
                                pageContext.localeResources[
                                    "chiSiamo.story.1958.date"
                                ]
                            }
                            year="1958"
                            image={story1958}
                        />
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.story.1958.text"
                                    ],
                            }}
                        ></p>
                    </div>
                    <div className="story-section">
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.story.60.text"
                                    ],
                            }}
                        ></p>
                        <StoriaNumber
                            text={
                                pageContext.localeResources[
                                    "chiSiamo.story.60.date"
                                ]
                            }
                            year="'60"
                            image={story60}
                        />
                    </div>
                    <div className="story-section">
                        <StoriaNumber
                            text={
                                pageContext.localeResources[
                                    "chiSiamo.story.70.date"
                                ]
                            }
                            year="'70"
                            image={story70}
                        />
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.story.70.text"
                                    ],
                            }}
                        ></p>
                    </div>
                    <div className="story-section">
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.story.80.text"
                                    ],
                            }}
                        ></p>
                        <StoriaNumber
                            text={
                                pageContext.localeResources[
                                    "chiSiamo.story.80.date"
                                ]
                            }
                            year="'80"
                            image={story80}
                        />
                    </div>
                    <div className="story-section">
                        <StoriaNumber
                            text={
                                pageContext.localeResources[
                                    "chiSiamo.story.90.date"
                                ]
                            }
                            year="'90"
                            image={story90}
                        />
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.story.90.text"
                                    ],
                            }}
                        ></p>
                    </div>
                    <div className="story-section">
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.story.2000.text"
                                    ],
                            }}
                        ></p>
                        <StoriaNumber
                            text={
                                pageContext.localeResources[
                                    "chiSiamo.story.2000.date"
                                ]
                            }
                            year="2000"
                            image={story00}
                        />
                    </div>
                    <div className="story-section">
                        <StoriaNumber
                            text={
                                pageContext.localeResources[
                                    "chiSiamo.story.2013.date"
                                ]
                            }
                            year="2013"
                            image={story2013}
                        />
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.story.2013.text"
                                    ],
                            }}
                        ></p>
                    </div>
                    <div className="story-section">
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.story.2019.text"
                                    ],
                            }}
                        ></p>
                        <StoriaNumber
                            text={
                                pageContext.localeResources[
                                    "chiSiamo.story.2019.date"
                                ]
                            }
                            year="2019"
                            image={story2019}
                        />
                    </div>
                    <div className="story-section">
                        <StoriaNumber
                            text={
                                pageContext.localeResources[
                                    "chiSiamo.story.dec2019.date"
                                ]
                            }
                            year="2019"
                            image={storydec2019}
                        />
                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageContext.localeResources[
                                        "chiSiamo.story.dec2019.text"
                                    ],
                            }}
                        ></p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ChiSiamo
