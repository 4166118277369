import React from "react";

import "./style.scss";

const StoriaNumber = ({ text, year, image }) => (
    <div className="story-number">
        <span>
            <h5 data-aos="fade-left" data-aos-offset="150">{text}</h5>
            <h4 data-aos="fade-right" data-aos-offset="150">{year}</h4>
        </span>
        {image && 
            <div className="image" style={{backgroundImage: `url(${image})`}}></div>
        }
    </div>

)

export default StoriaNumber
