import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import language_config from "../../../language-config";

import "./style.scss";

const StoryBanner = ({size, showButton, pageContext}) => {
    let [path, setPath] = useState('/');

    useEffect(() => {
        let newPath = ''
        if (typeof window !== 'undefined') {
            newPath = ((language_config[window.localStorage.language].is_default ? '' : ('/' + window.localStorage.language)) + '/')
        } else {
            newPath = '/'
        }
        setPath(newPath)
    }, []);
    
    return (
        <section className={`section-story ${size} rounded`}>
            <span className="subtitle white" data-aos="fade-up" data-aos-offset="0">{pageContext.localeResources['storyBanner.subtitle']}</span>
            <h4 data-aos="fade-up" data-aos-offset="150" dangerouslySetInnerHTML={{__html: pageContext.localeResources['storyBanner.title']}}></h4>
            {showButton && 
                <Link className="btn white" to={`${path}chi-siamo`} data-aos="fade-up" data-aos-offset="200" aria-label="vai alla storia del Gruppo Pam">{pageContext.localeResources['storyBanner.cta']}</Link>
            }
        </section>
    )
}

export default StoryBanner
